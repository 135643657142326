var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mb-4"},[_c('b-col'),_vm._v(" "),_c('b-col',{attrs:{"cols":12,"lg":_vm.$formOptions.inputCols}},[_c('b-form-group',[(!_vm.inline)?_c('div',[_c('b-button',{class:_vm.computedButtonClasses,attrs:{"block":!_vm.inline,"variant":_vm.value === (_vm.reverseOrder ? _vm.yesValue : _vm.noValue)
              ? _vm.variantActive
              : _vm.variantInactive,"pressed":false},on:{"click":function($event){return _vm.input(_vm.reverseOrder)}}},[_vm._v("\n          "+_vm._s(_vm.reverseOrder ? _vm.yesLabel : _vm.noLabel)+" ")]),_c('b-button',{class:_vm.computedButtonClasses,attrs:{"block":!_vm.inline,"variant":_vm.value === (!_vm.reverseOrder ? _vm.yesValue : _vm.noValue)
              ? _vm.variantActive
              : _vm.variantInactive,"pressed":false},on:{"click":function($event){return _vm.input(!_vm.reverseOrder)}}},[_vm._v("\n          "+_vm._s(!_vm.reverseOrder ? _vm.yesLabel : _vm.noLabel)+"\n        ")])],1):_c('b-button-group',{staticClass:"w-100"},[_c('b-button',{class:_vm.computedButtonClasses,attrs:{"block":!_vm.inline,"variant":_vm.value === (_vm.reverseOrder ? _vm.yesValue : _vm.noValue)
              ? _vm.variantActive
              : _vm.variantInactive,"pressed":false},on:{"click":function($event){return _vm.input(_vm.reverseOrder)}}},[_vm._v("\n          "+_vm._s(_vm.reverseOrder ? _vm.yesLabel : _vm.noLabel)+" ")]),_c('b-button',{class:_vm.computedButtonClasses,attrs:{"block":!_vm.inline,"variant":_vm.value === (!_vm.reverseOrder ? _vm.yesValue : _vm.noValue)
              ? _vm.variantActive
              : _vm.variantInactive,"pressed":false},on:{"click":function($event){return _vm.input(!_vm.reverseOrder)}}},[_vm._v("\n          "+_vm._s(!_vm.reverseOrder ? _vm.yesLabel : _vm.noLabel)+"\n        ")])],1)],1)],1),_vm._v(" "),_c('b-col')],1)}
var staticRenderFns = []

export { render, staticRenderFns }