































































































































































































































import { Insurer } from '@/types';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class BiQuoteSummaryItem extends Vue.extend({
  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    insurer: {
      type: Object as PropType<Insurer>,
      required: false,
      default: () => {
        return {};
      },
    },
    moreInfoRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    premium: {
      type: [String],
      required: false,
    },
    subPolicy: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClass: {
      type: [String, Object, Array],
      required: false,
      default: 'mb-5',
    },
    isInsured: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isPaid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  showItems = true;
  get premiumNumber(): number {
    return parseFloat(this.premium);
  }

  get premiumText(): string {
    if (
      this.isInsured === undefined ||
      this.isInsured === null ||
      this.isInsured === false
    ) {
      return 'Not Included';
    } else if (this.moreInfoRequired) {
      return 'More information required';
    } else if (this.isPaid) {
      return 'Paid';
    } else if (
      this.premium === undefined ||
      this.premium === null ||
      isNaN(this.premiumNumber) ||
      this.premiumNumber === 0
    ) {
      return 'Not Included';
    } else {
      return `$${this.premiumNumber.toFixed(2)}`;
    }
  }
}
