





























































































import {CwpAnswers, Policy} from '@/types';
import BiFormView from '@/components/bi-form-view/bi-form-view.vue';
import Vue from '@/vue';
import Component from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import moment from 'moment-timezone';
import BiTooltip from "@/components/bi-tooltip.vue";

@Component({
  components: { BiTooltip }
})
export default class DashboardPolicyCwpView extends Vue {
  @Prop({ type: Object, required: true }) data!: Policy<CwpAnswers>
  @Prop({ type: Boolean, default: false }) complete!: boolean
  @Prop({ type: Boolean, default: false }) showPremium!: boolean
  @Prop({ type: Boolean, default: false }) noEditForm!: boolean

  get formView(): BiFormView {
    return this.$refs.formView as BiFormView;
  }

  get startDate(): string {
    return moment(this.data.version.startDate).format('DD/MM/yyyy')
  }
  get endDate(): string {
    return moment(this.data.version.endDate).format('DD/MM/yyyy')
  }

  open(): void {
    this.formView.open();
  }
}
