import Vue from '@/vue';

interface ConversionOptions {
  send_to: string;
  value: number;
  currency?: string;
}

function createReportConversion(
  options: ConversionOptions
): (callback?: () => void) => false {
  return function (callback = () => void 0): false {
    window.gtag('event', 'conversion', {
      ...options,
      currency: 'NZD',
      event_callback: callback,
    });
    return false;
  };
}

const gtm = {
  reportConversion: {
    viewQuote: createReportConversion({
      send_to: process.env.VUE_APP_GTAG_VIEW_QUOTE_CONVERSION_ID,
      value: 500,
    }),
  },
};

export default function (): void {
  Vue.prototype.$gtm = gtm;

  if (
    process.env.NODE_ENV === 'production' &&
    process.env.VUE_APP_STAGING !== true
  ) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(...args) {
      window.dataLayer.push(args);
    };

    window.gtag('js', new Date());
    window.gtag('config', process.env.VUE_APP_GTAG_CONFIG_ID);
  } else {
    window.gtag = function gtag(...args) {
      console.log('gtag', args);
    };
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $gtm: typeof gtm;
  }
}

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
