


















































































import { Insurer } from '@/types';
import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    insurer: {
      type: Object as PropType<Insurer>,
      required: false,
      default: 'N/A',
    },
  },
})
export default class BiFinancialStrengthRating extends Vue {
  show(): void {
    (this.$refs.modal as BModal).show();
  }
  hide(): void {
    (this.$refs.modal as BModal).hide();
  }
}
