import { apiQuery } from '@/api';

export async function requote(this: Vue): Promise<string> {
  return new Promise((resolve, reject) => {
    this.$loading = true;
    apiQuery<{ hash: string }>(`/package/${this.$packageHash}/requote`)
      .then(({ hash }) => {
        resolve(`/quote/${hash}/your-details`);
      })
      .catch((e) => {
        this.$loading = false;
        console.error(e);
        reject(e);
      });
  });
}

export function home(this: Vue): string {
  return '/';
}

export function dashboard(this: Vue): string {
  return this.$dashboardPath;
}
