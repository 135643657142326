import Vue from '@/vue';

export default function (): void {
  // Builtin icons
  Vue.prototype.$icons = {
    get liability(): Array<string> {
      return [
        require(`@/assets/icons/Liability_Selected.svg`),
        require(`@/assets/icons/Liability_Unselected.svg`),
      ];
    },
    get toolsAndEquipment(): Array<string> {
      return [
        require(`@/assets/icons/Tools & Equip_Selected.svg`),
        require(`@/assets/icons/Tools & Equip_Unselected.svg`),
      ];
    },
    get commercialVehicles(): Array<string> {
      return [
        require(`@/assets/icons/Vehicle_Selected.svg`),
        require(`@/assets/icons/Vehicle_Unselected.svg`),
      ];
    },
    get professionalIndemnity(): Array<string> {
      return [
        require(`@/assets/icons/Pro_Selected.svg`),
        require(`@/assets/icons/Pro_Unselected.svg`),
      ];
    },
    get contractWorks(): Array<string> {
      return [
        require(`@/assets/icons/Contract Works_Selected.svg`),
        require(`@/assets/icons/Contract Works_Unselected.svg`),
      ];
    },
  };
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $icons: {
      contractWorks: [string, string];
    };
  }
}
