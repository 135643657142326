import Vue from '@/vue';

export default function (): void {
  // Import custom bi-* components
  const requireComponent = require.context(
    '@/components',
    true,
    /(bi-|Bi).+\.(vue)$/
  );

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    // Create component name from filename
    const componentName = fileName.split('/').pop()?.split('.')[0];

    Vue.component(
      componentName ?? componentConfig.default.name ?? componentConfig.name,
      componentConfig.default ?? componentConfig
    );
  });

  // Import views
  const requireView = require.context('@/views', true, /.+\.(vue)$/);

  requireView.keys().forEach((fileName) => {
    const viewConfig = requireView(fileName);
    // Create component name from filename
    const viewName = fileName.split('/').pop()?.split('.')[0];

    Vue.component(
      viewName ?? viewConfig.default.name ?? viewConfig.name,
      viewConfig.default ?? viewConfig
    );
  });
}
