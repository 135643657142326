import {
    LookupGeneral,
    LookupRegion,
    LookupCities,
    LookupSector,
    LookupWording,
    LookupInterestedParty,
} from '@/types';
import { LookupState } from '../states';

function processLookup<
    T extends LookupGeneral | LookupRegion | LookupCities | LookupSector | LookupWording | LookupInterestedParty,
>(
    array: T[],
    labelKey: keyof T,
    valueKey: keyof T = labelKey,
    all = false
): any[] {
    return array.map((lookup) => {
        return {
            text: lookup[labelKey],
            value: lookup[valueKey],
            ...(all ? lookup : {}),
        };
    });
}

const GeneralReturnTypeWrapper = () =>
    processLookup([] as LookupGeneral[], 'label');
export type GeneralLookupOption = ReturnType<
    typeof GeneralReturnTypeWrapper
>[number];
const RegionReturnTypeWrapper = () =>
    processLookup([] as LookupRegion[], 'region');
export type RegionLookupOption = ReturnType<
    typeof RegionReturnTypeWrapper
>[number];
const CitiesReturnTypeWrapper = () =>
    processLookup([] as LookupCities[], 'name');
export type CitiesLookupOption = ReturnType<
    typeof CitiesReturnTypeWrapper
>[number];
const SectorReturnTypeWrapper = () =>
    processLookup([] as LookupSector[], 'name');
export type SectorLookupOption = ReturnType<
    typeof SectorReturnTypeWrapper
>[number];
// const WordingReturnTypeWrapper = () =>
//   processLookup([] as LookupWording[], '');
// export type WordingLookupOption = ReturnType<
//   typeof WordingReturnTypeWrapper
// >[number];
const InterestedPartyReturnTypeWrapper = () =>
    processLookup([] as LookupInterestedParty[], 'name');
export type InterestedPartyLookupOption = ReturnType<
    typeof InterestedPartyReturnTypeWrapper
>[number];

export const general =
    (state: LookupState) =>
        (type: string, subtype?: string): GeneralLookupOption[] => {
            const lookup =
                subtype === undefined
                    ? state.general['']?.[type]
                    : state.general[type]?.[subtype];
            return lookup !== undefined ? processLookup(lookup, 'label', 'value') : [];
        };
export const region = (state: LookupState): RegionLookupOption[] => {
    return state.region !== undefined
        ? processLookup(state.region, 'region', 'region', true)
        : [];
};
export const cities = (state: LookupState): CitiesLookupOption[] => {
    return state.cities !== undefined
        ? processLookup(state.cities, 'name', 'name', true)
        : [];
};
export const sector = (state: LookupState): SectorLookupOption[] => {
    return state.sector !== undefined ? processLookup(state.sector, 'name') : [];
};
export const interestedParty = (state: LookupState) => (
    type: string,
  ): InterestedPartyLookupOption[] => {
    return state.interestedParty !== undefined
      ? processLookup(state.interestedParty, 'name',)
      : [];
  };
// export const wording = (
//   state: LookupState
// ): ((type: string) => WordingLookupOption[]) => (
//   type: string
// ): WordingLookupOption[] => {
//   const lookup = state.wording[type];
//   return lookup !== undefined
//     ? processLookup(lookup, 'wording_name', 'wording_url')
//     : [];
// };

type StringReturnType = { text: string; value: string }[];

export const months = (): StringReturnType => {
    return [
        {text: 'January', value: '1'},
        {text: 'February', value: '2'},
        {text: 'March', value: '3'},
        {text: 'April', value: '4'},
        {text: 'May', value: '5'},
        {text: 'June', value: '6'},
        {text: 'July', value: '7'},
        {text: 'August', value: '8'},
        {text: 'September', value: '9'},
        {text: 'October', value: '10'},
        {text: 'November', value: '11'},
        {text: 'December', value: '12'},
    ];
};

type yearsLookupType = (options: {
    range: number;
    from: number;
}) => StringReturnType;
export const years =
    (): yearsLookupType =>
        ({range, from}: { range?: number; from?: number }): StringReturnType => {
            const options: StringReturnType = [];

            const thisYear = new Date().getFullYear();
            let y: number;
            if (from !== undefined) {
                y = from;
            } else if (range !== undefined) {
                y = new Date().getFullYear() - range;
            } else {
                return options;
            }

            for (y; y <= thisYear; y++) {
                const year = y.toString();
                options.push({text: year, value: year});
            }

            return options.reverse();
        };

type numberRangeLookupType = (options: {
    to: number;
    from: number;
}) => StringReturnType;
export const numberRange =
    (): numberRangeLookupType =>
        ({to, from = 0}: { to: number; from?: number }): StringReturnType => {
            return Array.from(
                Array.from(
                    Array(to - from)
                ).keys()
            ).map(i => ({
                text: (i + 1 + from).toString(),
                value: (i + 1 + from).toString()
            }));
        };