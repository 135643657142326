const requireInitModules = require.context(
  './',
  false,
  /^(?!.*(?:index\.ts$)).*\.ts$/
);

const initFns: (() => void)[] = [];

requireInitModules.keys().forEach((fileName) => {
  const module = requireInitModules(fileName).default;
  initFns.push(module);
});

export default function (): void {
  for (const initFn of initFns) {
    initFn();
  }
}
