import { apiQuery } from '@/api';
import { Package, PackageStatus } from '@/types';
import { AxiosResponse } from 'axios';
import { NavigationGuard } from 'vue-router';

export function getStatusPaths(
  packageHash: string
): Record<string, { preferred: string | (() => string); allowed: string[] }> {
  return {
    Draft: {
      preferred: `/quote/${packageHash}/form/contract-works/page1`,
      allowed: [
        `/quote/${packageHash}/form`,
      ],
    },
    Saved: {
      preferred: `/quote/${packageHash}/form/contract-works/page1`,
      allowed: [
        `/quote/${packageHash}/form`,
      ],
    },
    AutoSaved: {
      preferred: `/quote/${packageHash}/form/contract-works/page1`,
      allowed: [
        `/quote/${packageHash}/form`,
      ],
    },
    Quoted: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`,
      ],
    },
    ReferForQuote: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/dashboard`
      ],
    },
    ApplicationStarted: {
      preferred: `/quote/${packageHash}/apply`,
      allowed: [`/quote/${packageHash}/apply`],
    },
    ProceedToPayment: {
      preferred: `/quote/${packageHash}/payment`,
      allowed: [
        `/quote/${packageHash}/payment`,
        `/quote/${packageHash}/payment/thanks`, // TODO: is this supposed to be here?
      ],
    },
    AwaitingPayment: {
      preferred: `/quote/${packageHash}/payment/thanks`,
      allowed: [`/quote/${packageHash}/payment/thanks`],
    },
    Applied: {
      preferred: `/quote/${packageHash}/payment`,
      allowed: [
        `/quote/${packageHash}/apply`,
        `/quote/${packageHash}/payment`,
        `/quote/${packageHash}/payment/thanks`,
      ],
    },
    Accepted: {
      preferred: `/quote/${packageHash}/apply`,
      allowed: [`/quote/${packageHash}/view`, `/quote/${packageHash}/apply`],
    },
    AuthorisedQuote: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
          `/quote/${packageHash}/dashboard`,
      ],
    },
    Declined: {
      preferred: `/quote/${packageHash}/declined`,
      allowed: [`/quote/${packageHash}/declined`],
    },
    Expired: {
      preferred: `/quote/${packageHash}/expired`,
      allowed: [`/quote/${packageHash}/expired`],
    },
    Superseded: {
      preferred: `/quote/${packageHash}/superseded`,
      allowed: [`/quote/${packageHash}/superseded`],
    },
    Current: {
      preferred: `/quote/${packageHash}/current`,
      allowed: [`/quote/${packageHash}/current`],
    },
  };
}

async function getPackageStatus(
  hash: string
): Promise<PackageStatus | undefined> {
  try {
    if (!valueExists(hash)) {
      return undefined;
    }
    const { status } = await apiQuery<{ status: PackageStatus }>(
      `/packages/${hash}/status`
    );
    return status;
  } catch (e) {
    return undefined;
  }
}
function valueExists(value: string | null | undefined) {
  return value !== undefined && value !== null && value.trim() !== '';
}

function getIsAllowedPath(path: string, allowedPaths: string[]) {
  for (const allowedPath of allowedPaths) {
    if (path.startsWith(allowedPath)) {
      return true;
    }
  }
  return false;
}

function resolvePreferredStatusPath(preferred: string | (() => string)) {
  if (typeof preferred === 'function') {
    return preferred();
  } else {
    return preferred;
  }
}

const stateMachine: NavigationGuard = async (to, from, next): Promise<void> => {
  const hasHashParam = to.matched
    .map((r) => r.path.includes(':packageHash'))
    .includes(true);
  if (
    hasHashParam &&
    (process.env.NODE_ENV !== 'development' ||
      process.env.VUE_APP_NAVGUARD === undefined ||
      process.env.VUE_APP_NAVGUARD !== 'false')
  ) {
    const packageHash = to.params.packageHash;
    let status: PackageStatus | undefined;

    if (!to.meta.noHashAllowed) {
      if (!valueExists(packageHash)) {
        next('/');
        return;
      } else {
        try {
          status = await getPackageStatus(packageHash);
          if (status === undefined) {
            return next('/');
          }
        } catch (error) {
          return next('/');
        }
      }
    } else if (packageHash !== undefined) {
      next();
      return;
    }

    status = await getPackageStatus(packageHash);
    if (status !== undefined) {
      const statusPaths = getStatusPaths(packageHash)[status] ?? {
        preferred: '/404',
        allowed: [],
      };

      if (getIsAllowedPath(to.path, statusPaths.allowed) === false) {
        const preferredPath = resolvePreferredStatusPath(statusPaths.preferred);
        next(preferredPath);
        return;
      }
    }
  }
  next();
};

export default stateMachine;
