









































import { PolicyClaim } from '@/types';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import BiClaimItem from './bi-claim-item.vue';
import { Prop } from "vue-property-decorator";

@Component
export default class BiClaimList extends Vue {
  @Prop({ type: String, default: 'Claim'}) title!: string;
  @Prop({ type: String, required: true }) question!: string;
  @Prop({ type: String, default: 'Add Claim'}) addButtonText!: string;
  @Prop({ type: String, default: 'Remove Claim'}) removeButtonText!: string;
  @Prop({ type: Array as PropType<Partial<PolicyClaim>[]>, default: (): PolicyClaim[] => []}) value!: Partial<PolicyClaim>[];
  @Prop({ type: Array, default: (): { value: string | number; text: string }[] => []}) claimMadeByOptions!: { value: string | number; text: string }[];
  @Prop({ type: Array, default: (): { value: string | number; text: string }[] => []}) eventOptions!: { value: string | number; text: string }[];
  @Prop({ type: Array, default: (): { value: string | number; text: string }[] => []}) dateMonthOptions!: { value: string | number; text: string }[];
  @Prop({ type: Array, default: (): { value: string | number; text: string }[] => []}) dateYearOptions!: { value: string | number; text: string }[];
  @Prop({ type: String, default: 'b-select'}) whatHappenedInputType!: string;
  @Prop({ type: Object, default: () => ({})}) whatHappenedInputOptions!: Record<string, any>;
  @Prop({
    type: [Boolean, Function] as PropType<boolean | ((value: PolicyClaim) => boolean)>,
    default: () => (value: PolicyClaim): boolean => {
      return value?.whatHappened?.toLowerCase() === 'other';
    }
  }) showDescriptionField!: boolean | ((value: PolicyClaim) => boolean);

  touch(): void {
    if (this.$refs.claimItem) {
      for (const claim of this.$refs.claimItem as BiClaimItem[]) {
        claim.touch();
      }
    }
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }

  addItem(): void {
    this.value.push({
      question: this.question,
      claimMadeBy: undefined,
      whatHappened: undefined,
      whatHappenedOther: undefined,
      claimMonth: undefined,
      claimYear: undefined,
      claimValue: undefined,
    });
    this.emitInput();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.emitInput();
  }
}
