import Vue from '@/vue';

export default function (): void {
  // Enable dev mode for testing purposes
  Vue.prototype.$dev = process.env.NODE_ENV === 'development';
  Vue.prototype.$testingMode = Vue.observable({ enabled: false });

  if (process.env.VUE_APP_STAGING === true) {
    Vue.config.devtools = true;
  }

  // Pause helper function
  Vue.prototype.$pause = async function (duration: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  };
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $dev: boolean;
    $testingMode: { enabled: boolean };
    $pause: (duration: number) => Promise<void>;
  }
}
