import Vue from '@/vue';
import { GlobalState } from '@/store';

export const setEmailAsked = (
  state: GlobalState,
  { emailAsked }: { emailAsked: boolean }
): void => {
  Vue.set(state, 'emailAsked', emailAsked);
};

export const reset = (state: GlobalState): void => {
  Vue.set(state, 'emailAsked', false);
  Vue.set(state, 'packageIsFreshlyCreated', false);
};
