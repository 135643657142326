







import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class BiLoadable extends Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}) {}
