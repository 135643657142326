import { RouteConfig } from 'vue-router';
import * as handlers from './handlers';
import {getPackage, getPackageClient, getPackagePolicy} from "@/api/packages";
import {Client, CwpAnswers, LookupSector, PackageStatus, Policy, PolicyType} from "@/types";
import { showPage3, showPage4, showPage5 } from "@/helpers/conditionalQuestions";
import {getRegionLookup} from "@/api/lookups";

const routes: Array<RouteConfig> = [
  { path: '', component: () => import('@/pages/WelcomePage.vue') },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        name: 'quote',
        path: 'quote/:packageHash?',
        component: () => import('@/layouts/QuoteLayout.vue'),
        children: [
          {
            path: 'dashboard',
            component: () => import('@/pages/DashboardPage.vue'),
            meta: {
              async canGoBack(): Promise<boolean> {
                const pkg = await getPackage(this.$packageHash as string);
                return pkg.status !== PackageStatus.REFER_FOR_QUOTE;
              },
              back (this: Vue): string { return this.$getFormPath('contract-works', 'page9'); }
            },
          },
          {
            path: 'apply',
            component: () => import('@/pages/application/ApplyPage.vue'),
            meta: {
              canGoBack: false,
              back: handlers.dashboard
            }
          },
          {
            path: 'payment/thanks',
            component: () => import('@/pages/payment/SuccessPage.vue'),
            meta: {
              back: handlers.home
            }
          },
          {
            path: 'payment',
            component: () => import('@/pages/payment/PaymentPage.vue'),
            meta: {
              canGoBack: false,
              back(this: Vue): string {
                return `/quote/${this.$packageHash}/apply`;
              }
            }
          },

          // {
          //   path: 'payment/finance',
          //   component: () => import('@/pages/payment/FinancialSynergyPage.vue'),
          //   meta: {
          //     back(this: Vue): string {
          //       return `/quote/${this.$packageHash}/payment`
          //     }
          //   }
          // },
        ]
      },
      {
        name: 'form',
        path: 'quote/:packageHash?/form/contract-works',
        component: () => import('@/layouts/QuoteLayout.vue'),
        children: [
          {
            path: 'page1',
            component: () => import('@/pages/quote/forms/cwp/CwpPage1.vue'),
            meta: {
              back: handlers.home,
              noHashAllowed: true,
            },
          },
          {
            path: 'page2',
            component: () => import('@/pages/quote/forms/cwp/CwpPage2.vue'),
            meta: {
              back (this: Vue) { return this.$getFormPath('contract-works', 'page1'); }
            },
          },
          {
            path: 'page3',
            component: () => import('@/pages/quote/forms/cwp/CwpPage3.vue'),
            meta: {
              back (this: Vue) { return this.$getFormPath('contract-works', 'page2'); }
            },
          },
          {
            path: 'page4',
            component: () => import('@/pages/quote/forms/cwp/CwpPage4.vue'),
            meta: {
              async back(this: Vue) {
                const policy = await getPackagePolicy<CwpAnswers>(this.$packageHash as string, PolicyType.CWP);

                return this.$getFormPath('contract-works', showPage3(policy.version) ? 'page3' : 'page2');
              }
            },
          },
          {
            path: 'page5',
            component: () => import('@/pages/quote/forms/cwp/CwpPage5.vue'),
            meta: {
              async back (this: Vue) {
                const [client, regions] = <[
                  Client,
                  LookupSector[],
                ]>await Promise.all<Policy<CwpAnswers> | Client | LookupSector[]>([
                  getPackageClient(this.$packageHash as string),
                  getRegionLookup(),
                ]);

                return this.$getFormPath('contract-works', showPage4(client, regions) ? 'page4' : 'page3');
              }
            },
          },
          {
            path: 'page6',
            component: () => import('@/pages/quote/forms/cwp/CwpPage6.vue'),
            meta: {
              async back(this: Vue) {
                const [policy, client, regions] = <[
                  Policy<CwpAnswers>,
                  Client,
                  LookupSector[],
                ]>await Promise.all<Policy<CwpAnswers> | Client | LookupSector[]>([
                  getPackagePolicy<CwpAnswers>(this.$packageHash as string, PolicyType.CWP),
                  getPackageClient(this.$packageHash as string),
                  getRegionLookup(),
                ]);

                let page: string;
                if(showPage5(policy.version)) {
                  page = 'page5'
                } else if (showPage4(client, regions)) {
                  page = 'page4'
                } else {
                  page = 'page3'
                }

                return this.$getFormPath('contract-works', page);
              }
            },
          },
          {
            path: 'page7',
            component: () => import('@/pages/quote/forms/cwp/CwpPage7.vue'),
            meta: {
              back (this: Vue) { return this.$getFormPath('contract-works', 'page6'); }
            },
          },
          {
            path: 'page8',
            component: () => import('@/pages/quote/forms/cwp/CwpPage8.vue'),
            meta: {
              back (this: Vue) { return this.$getFormPath('contract-works', 'page7'); }
            },
          },
          {
            path: 'page9',
            component: () => import('@/pages/quote/forms/cwp/CwpPage9.vue'),
            meta: {
              async back(this: Vue) {
                const policy = await getPackagePolicy<CwpAnswers>(this.$packageHash as string, PolicyType.CWP);

                return this.$getFormPath('contract-works', policy.isReferred ? 'page8' : 'page7');
              }
            }
          },

          // {
          //   path: 'superseded',
          //   component: () => import('@/pages/error/ErrorPackageSupersededPage.vue'),
          //   meta: {
          //     back: handlers.home
          //   }
          // },
          // {
          //   path: 'current',
          //   component: () => import('@/pages/error/ErrorPackageCurrentPage.vue'),
          //   meta: {
          //     back: handlers.home
          //   }
          // },
          // {
          //   path: 'declined',
          //   component: () => import('@/pages/error/ErrorPackageDeclinedPage.vue'),
          //   meta: {
          //     back: handlers.home
          //   }
          // },
          // {
          //   path: 'expired',
          //   component: () => import('@/pages/error/ErrorPackageExpiredPage.vue'),
          //   meta: {
          //     back: handlers.home
          //   }
          // },
        ],
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/pages/error/Error404Page.vue'),
  },
];

export default routes;
