import global from './global';
import lookups from './lookups';
import client from './client';
// import finance from './finance'

const stores = {
  global,
  lookups,
  client,
  // finance
};

export type Actions = {
  global: { [a in keyof typeof stores.global.actions]: `global/${a}` };
  lookups: { [a in keyof typeof stores.lookups.actions]: `lookups/${a}` };
  client: { [a in keyof typeof stores.client.actions]: `client/${a}` };
  // finance: { [ a in keyof typeof stores.finance.actions ]: `finance/${a}` };
};

export type Mutations = {
  global: { [m in keyof typeof stores.global.mutations]: `global/${m}` };
  lookups: { [m in keyof typeof stores.lookups.mutations]: `lookups/${m}` };
  client: { [m in keyof typeof stores.client.mutations]: `client/${m}` };
};

export type Getters = {
  lookups: { [g in keyof typeof stores.lookups.getters]: `lookups/${g}` };
};

export const actions: Actions = {
  global: {
    reset: 'global/reset',
  },
  lookups: {
    getGeneralLookup: 'lookups/getGeneralLookup',
    getRegionLookup: 'lookups/getRegionLookup',
    getCitiesLookup: 'lookups/getCitiesLookup',
    getSectorLookup: 'lookups/getSectorLookup',
    getInterestedPartyLookup: 'lookups/getInterestedPartyLookup'
  },
  client: {
    reset: 'client/reset',
  },
  // finance: {
  //   createQuote: 'finance/createQuote',
  //   saveBankInfo: 'finance/saveBankInfo',
  // },
};

export const mutations: Mutations = {
  global: {
    setEmailAsked: 'global/setEmailAsked',
    reset: 'global/reset',
  },
  lookups: {
    general: 'lookups/general',
    region: 'lookups/region',
    cities: 'lookups/cities',
    sector: 'lookups/sector',
    trade: 'lookups/trade',
    lossOfUsePremiums: 'lookups/lossOfUsePremiums',
    wording: 'lookups/wording',
    interestedParty: 'lookups/interestedParty'
  },
  client: {
    setFirstName: 'client/setFirstName',
    setLastName: 'client/setLastName',
    setName: 'client/setName',
    reset: 'client/reset',
  },
};

export const getters: Getters = {
  lookups: {
    general: 'lookups/general',
    region: 'lookups/region',
    cities: 'lookups/cities',
    sector: 'lookups/sector',
    // wording: 'lookups/wording',
    years: 'lookups/years',
    months: 'lookups/months',
    numberRange: "lookups/numberRange",
    interestedParty: 'lookups/interestedParty',
  },
};
