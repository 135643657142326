

































import Vue from '@/vue';

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { CleaveOptions } from "cleave.js/options";

@Component
export default class BiFormItemInput extends Vue {
  @Prop({type: String, default: ''}) label!: string;
  @Prop({type: String, required: true}) value!: string;
  @Prop({type: String, default: ''}) placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly!: boolean;
  @Prop({type: Boolean, default: false}) disabled!: boolean;

  @Prop({type: [Boolean, String], default: false}) error!: boolean | string;

  @Prop({type: Boolean, default: false}) currency!: boolean;
  @Prop({type: Boolean, default: false}) percentage!: boolean;
  @Prop({type: Object, default: undefined, required: false}) cleave!: CleaveOptions & Record<string, any>;

  get _value(): string {
    return this.value;
  }

  set _value(value: string) {
    this.$emit('input', value);
  }

  get _id(): string {
    return `input-${
        this.$attrs.id
            ? this.$attrs.id
            : this.label.replaceAll(' ', '-').toLowerCase()
    }`;
  }

  get cleaveConfig(): CleaveOptions & Record<string, any> | undefined {
    const config = {
      swapHiddenInput: true,
    };

    if (this.currency) {
      return {
        ...config,
        prefix: '$',
        rawValueTrimPrefix: true,
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralIntegerScale: 8,
        numeralPositiveOnly: true,
      }
    } else if (this.percentage) {
      return {
        ...config,
        prefix: '%',
        tailPrefix: true,
        numeral: true,
        numeralIntegerScale: 2,
        numeralPositiveOnly: true,
      }
    } else if (this.cleave) {
      return {
        ...config,
        ...this.cleave
      };
    }

    return undefined;
  }
}
