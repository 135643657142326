














































































































































import { PolicyClaim } from '@/types';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";

@Component
export default class BiClaimItem extends Vue {
  @Prop({
    type: Object as PropType<PolicyClaim>,
    default: (): Partial<PolicyClaim> => {
      return {
        claimMadeBy: undefined,
        whatHappened: undefined,
        whatHappenedOther: undefined,
        claimMonth: undefined,
        claimYear: undefined,
        claimValue: undefined,
      };
    }
  }) value!: Partial<PolicyClaim>;
  @Prop({
    type: Array,
    required: true,
    default: (): { value: string | number; text: string }[] => []
  }) claimMadeByOptions!: { value: string | number; text: string }[];
  @Prop({ type: Array, default: (): { value: string | number; text: string }[] => [] }) eventOptions!: {
    value: string | number;
    text: string
  }[];
  @Prop({
    type: Array,
    required: true,
    default: (): { value: string | number; text: string }[] => []
  }) dateMonthOptions!: { value: string | number; text: string }[];
  @Prop({
    type: Array,
    required: true,
    default: (): { value: string | number; text: string }[] => []
  }) dateYearOptions!: { value: string | number; text: string }[];
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: 'b-select' }) whatHappenedInputType!: string;
  @Prop({ type: Object, default: () => ({}) }) whatHappenedInputOptions!: Record<string, any>;
  @Prop({
    type: [Boolean, Function] as PropType<boolean | ((value: PolicyClaim) => boolean)>,
    default: () => (value: PolicyClaim): boolean => {
      return value?.whatHappened?.toLowerCase() === 'other';
    }
  }) showDescriptionField!: boolean | ((value: Partial<PolicyClaim>) => boolean);

  @Validations()
  validations = {
    value: {
      claimMadeBy: { required },
      whatHappened: { required },
      whatHappenedOther: {
        required: requiredIf((value) => {
          if (this.showDescriptionField === true) {
            return true;
          } else if (value.whatHappened !== undefined && this.showDescriptionField !== false) {
            return this.showDescriptionField(this.value);
          }
          return false;
        }),
      },
      claimMonth: { required },
      claimYear: { required },
      claimValue: { required },
    },
  };

  handleInput(field: keyof PolicyClaim) {
    this.emitInput();
    this.$v.value[field]?.$touch();
  }

  touch(): void {
    this.$v.value.$touch();
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }
}
