import {Client, CwpAnswers, Package, Policy, PolicySet, PolicyType} from '@/types';
import { apiQuery } from '@/api';
import { CreatePackageDto } from '@/types/api/packages/CreatePackageDto';
import {CreatePolicyDto} from "@/types/api/policies/CreatePolicyDto";

export const getPackage = async (hash: string): Promise<Package> => {
  return apiQuery<Package>(`/packages/${hash}`);
};
export const createPackage = async (
  data: CreatePackageDto
): Promise<Package> => {
  return apiQuery<Package>('/packages', { method: 'POST', data });
};
export const updatePackage = async (
  hash: string,
  data: Partial<CreatePackageDto>
): Promise<Package> => {
  return apiQuery<Package>(`/packages/${hash}`, { method: 'PATCH', data });
};
export const getPackagePolicies = async (hash: string): Promise<PolicySet> => {
  return apiQuery<PolicySet>(`/packages/${hash}/policies`);
};
export const getPackagePolicy = async <T = null>(
  hash: string,
  type: PolicyType
): Promise<Policy<T>> => {
  return apiQuery<Policy<T>>(`/packages/${hash}/policies/${type}`);
};
export const createPackagePolicy = async <T>(
  hash: string,
  data: CreatePolicyDto<T>
): Promise<Package> => {
  return apiQuery<Package>(`/packages/${hash}/policies`, { method: 'POST', data });
};
export const updatePackagePolicy = async <T>(
  hash: string,
  type: string,
  data: Partial<CreatePolicyDto<Partial<T>>>
): Promise<Policy<T>> => {
  return apiQuery<Policy<T>>(`/packages/${hash}/policies/${type}`, { method: 'PATCH', data: { policyCode: type, ...data } });
};

export const getPackageClient = async (hash: string): Promise<Client> => {
  return apiQuery<Client>(`/packages/${hash}/client`);
};