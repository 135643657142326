import {
  LookupGeneral,
  LookupInterestedParty,
  LookupRegion,
  LookupCities,
  LookupSector,
  Package,
  Policy,
  PolicyType,
} from '@/types';
import { apiQuery } from '@/api';
import { CreatePackageDto } from '@/types/api/packages/CreatePackageDto';

export async function getGeneralLookup(type: string): Promise<LookupGeneral[]>;
export async function getGeneralLookup(
  type: string,
  subtype?: string
): Promise<LookupGeneral[]>;
export async function getGeneralLookup(
  type: string,
  subtype?: string
): Promise<LookupGeneral[]> {
  return apiQuery<LookupGeneral[]>('/lookups/general', {
    params: { type, subtype },
  });
}

export async function getRegionLookup(): Promise<LookupSector[]> {
  return apiQuery<LookupSector[]>('/lookups/regions');
}

export async function getSectorLookup(): Promise<LookupRegion[]> {
  return apiQuery<LookupRegion[]>('/lookups/sectors');
}

export async function getInterestedPartyLookup(
  type: string
): Promise<LookupInterestedParty[]> {
  return apiQuery<LookupInterestedParty[]>('/lookups/interested-party', {
    params: { type },
  });
}

export async function getCitiesLookup(): Promise<LookupCities[]> {
  return apiQuery<LookupCities[]>('/lookups/cities');
}