

































































import Vue from '@/vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class BiYesNo extends Vue {
  @Prop({ required: false, default: null }) value!: any | null;
  input(value: boolean): void {
    this.$emit('input', value ? this.yesValue : this.noValue);
  }

  @Prop({ type: Boolean, required: false, default: false })
  reverseOrder!: boolean;

  @Prop({ type: String, required: false, default: 'Yes' }) yesLabel!: string;
  @Prop({ required: false, default: true }) yesValue!: any;
  @Prop({ type: String, required: false, default: 'No' }) noLabel!: string;
  @Prop({ required: false, default: false }) noValue!: any;

  @Prop({ type: String, required: false, default: 'outline-primary' })
  variantInactive!: string;
  @Prop({ type: String, required: false, default: 'primary' })
  variantActive!: string;

  @Prop({ type: Boolean, required: false, default: false }) inline!: boolean;
  @Prop({ type: Object, required: false, default: () => ({}) })
  buttonClasses!: { [c: string]: boolean };

  get computedButtonClasses(): { [c: string]: boolean } {
    const buttonClasses = Object.keys(this.buttonClasses);
    const widthClass = this.inline ? 'w-50' : 'w-100';

    if (buttonClasses.some((c) => /w-[0-9]+/.test(c))) {
      return this.buttonClasses;
    } else {
      return { [widthClass]: true, ...this.buttonClasses };
    }
  }
}
