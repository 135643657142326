import Vue from '@/vue';
import {
  actions,
  Actions,
  getters,
  Getters,
  mutations,
  Mutations,
} from '@/store';

export default function (): void {
  Vue.prototype.$actions = actions;
  Vue.prototype.$mutations = mutations;
  Vue.prototype.$getters = getters;
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $actions: Actions;
    $mutations: Mutations;
    $getters: Getters;
  }
}
