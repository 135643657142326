import Vue from '@/vue';
// @ts-ignore-next-line
import Cleave from '@/utils/cleave.js/src/Cleave.js';

export default function (): void {
  // Get package hash from route params
  Object.defineProperty(Vue.prototype, '$packageHash', {
    get: function (this: Vue): string | undefined {
      return this.$route.params.packageHash || undefined;
    },
  });

  // Simple deep clone object
  Vue.prototype.$clone = function <T>(this: Vue, state: T): T {
    return JSON.parse(JSON.stringify(state));
  };

  // Get path string for form page
  Vue.prototype.$getFormPath = function (
    this: Vue,
    form: string,
    page: string | number,
    params?: { [k: string]: string }
  ) {
    let query = '';
    let path = `/quote/${this.$packageHash}/form/${form}/${page}`;

    if (params !== undefined && Object.keys(params).length !== 0) {
      query = Object.entries(params)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');
      path += `?${query}`;
    }

    return path;
  };

  // Get path for dashboard
  Object.defineProperty(Vue.prototype, '$dashboardPath', {
    get: function (this: Vue): string {
      return `/quote/${this.$packageHash}/dashboard`;
    },
  });

  // Full page loading spinner
  let _loading = Vue.observable(false);
  let _loadingModal: Vue | undefined = undefined;

  Object.defineProperty(Vue.prototype, '$loadingModal', {
    get: function (this: Vue) {
      return _loadingModal;
    },
    set: function (this: Vue, value: Vue) {
      if (_loadingModal === undefined) {
        _loadingModal = value;
      } else {
        throw 'Loading modal has already been set';
      }
    },
  });

  Object.defineProperty(Vue.prototype, '$loading', {
    get: function (this: Vue) {
      return _loading;
    },
    set: function (this: Vue, value: boolean) {
      _loading = value;
      if (this.$loadingModal !== undefined) {
        this.$loadingModal?.show(value);
        this.$forceUpdate();
      }
    },
  });

  // Name of current page transition (placeholder variable)
  Vue.prototype.$pageTransition = '';

  // Show error modal for failing form save
  Vue.prototype.$showFormSaveError = async function (
    this: Vue
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.$bvModal
        .msgBoxConfirm('An error has occured. Please try again later.', {
          title: 'Error',
          okTitle: 'Return to dashboard',
          buttonSize: 'sm',
          centered: true,
        })
        .then((ok: boolean) => {
          if (ok) {
            this.$router.push(this.$dashboardPath);
          }
          resolve(ok);
        });
    });
  };

  Vue.prototype.$isDefined = function <T extends string | number | boolean>(
    value: T | undefined | null
  ): T | undefined | null {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (typeof value === 'string') {
      return value.length > 0 ? value : null;
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
      return value;
    }
  };

  Vue.directive('cleave', {
    inserted: (el, binding) => {
      if (!binding.value) {
        return;
      }

      el.cleave = new Cleave(el, binding.value || {});

      if (!(el.cleave.properties as any).swapHiddenInput) {
        return;
      }

      const cleaveEl = el.parentElement?.querySelector('input:not([type=hidden])')

      // Handle Blur events as there is no property on Cleave to handle this
      if (cleaveEl !== undefined && cleaveEl !== null) {
        const blurEvent = new Event('blur', {bubbles: true});
        cleaveEl.addEventListener('blur', (e) => {
          el.dispatchEvent(blurEvent);
        })
      }

      const event = new Event('input', {bubbles: true});
      el.cleave.properties.onValueChanged = (e: Event) => {
          el.dispatchEvent(event);
      }
    },
    update: (el, binding) => {
      if (!binding.value) {
        return;
      }

      const event = new Event('input', { bubbles: true });

      setTimeout(() => {
        if (!(el.cleave.properties as any).swapHiddenInput) {
          (el as HTMLInputElement).value = el.cleave.properties.result
        } else if ((el as HTMLInputElement).value !== el.cleave.getRawValue()) {
          el.cleave.setRawValue((el as HTMLInputElement).value)
        }

        el.dispatchEvent(event)
      }, 100)
    },
  });
}

Vue.prototype.$toCurrency = function (value: unknown) {
  if (value === null || value === undefined) {
    return "Not Included";
  }
  if (typeof value !== "number") {
      return value;
  }

  return value.toLocaleString('en-US', {
    currency: 'USD',
    // eslint-disable-next-line
    // @ts-ignore - trailingZeroDisplay is not in the types, but it exists in the API (outdated types?)
    trailingZeroDisplay: value !== 0 ? 'stripIfInteger' : 'auto',
    style: 'currency'
  })
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $packageHash: string | undefined;
    $clone: <T>(this: Vue, state: T) => T;
    $getFormPath: (
      this: Vue,
      form: string,
      page: string | number,
      params?: { [k: string]: string }
    ) => string;
    $dashboardPath: string;
    $loading: boolean;
    // This intersection type is to prevent typing errors as i'm getting errors setting the type of _loadingModal to BiLoading
    $loadingModal?: Vue & { show: (value: boolean) => boolean };
    $pageTransition: string;
    $showFormSaveError: (this: Vue) => Promise<void>;
    $isDefined: <T extends string | number | boolean>(
      value: T | undefined | null
    ) => T | undefined;
    $toCurrency: (value: unknown) => string;
  }
}
