












































































































































































































































import Vue from '@/vue';
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { helpers, required, requiredIf } from 'vuelidate/lib/validators';
import { apiQuery } from "@/api";
import { BModal } from "bootstrap-vue";
import { Prop } from "vue-property-decorator";
import { PropType } from "vue";
import { Client, CwpAnswers, Policy } from "@/types";

const phone = (value: string) => {
  if (value !== undefined && value !== null) {
    value = value.trim();
    // Match numeric, ignoring whitespace
    return (value.match(/^[\d +]*$/g) || [''])[0].length >= 5;
  }
  return true;
};

// Regex from https://emailregex.com/
const isEmail = helpers.regex(
    'email',
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
);

@Component
export default class BiYourInformationModal extends Vue {
  @Prop({ type: Object as PropType<Client>, required: true }) client!: Client;
  @Prop({ type: Object as PropType<Policy<CwpAnswers>>, required: true }) policy!: Policy<CwpAnswers>;

  form = {
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    otherInfoInsurer: '',
    referral: '',
    referralOther: '',
  }

  @Validations()
  validations = {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, isEmail },
      telephone: { required, phone },
      otherInfoInsurer: {},
      referral: { required },
      referralOther: {
        required: requiredIf(function (client) {
          return (
              client?.referral !== undefined &&
              client.referral !== null &&
              client.referral.toLowerCase() === 'other'
          );
        }),
      },
    },
  }

  clicked = false;
  saving = false;

  get referredByLookup(): { text: string; value: string; }[] {
    return this.$store.getters[this.$getters.lookups.general]('referral');
  }

  get showReferralOther(): boolean {
    return this.form.referral.toLowerCase() === 'other';
  }

  get modal() { return this.$refs.modal as BModal; }


  async saveInfo(): Promise<void> {
    await Promise.all([
      apiQuery(
          `/packages/${this.$packageHash}`,
          { method: "PATCH", data: { client: this.form }},
      ),
      apiQuery(
          `/packages/${this.$packageHash}/policies/cwp`,
          { method: "PATCH", data: { version: { otherInfoInsurer: this.form.otherInfoInsurer }}},
      ),
    ]);
  }

  async onCancel() {
    this.modal.hide();
    this.$emit('cancel');
  }

  async onSubmit(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$invalid) { return; }

    this.saving = true;

    try {
      await this.saveInfo();
      await apiQuery(`/packages/${this.$packageHash}/action/quote`, { method: 'POST' });
    } catch (e) {
      console.error(e);
    } finally {
      this.saving = false;
      this.modal.hide();
      this.$emit('submit')
    }
  }

  async mounted() {
    await Promise.all([
      this.$store.dispatch(this.$actions.lookups.getGeneralLookup, { type: 'referral' }),
    ]);

    this.modal.show();
  }

  async beforeMount() {
    this.form = {
      firstName: this.client.firstName ?? '',
      lastName: this.client.lastName ?? '',
      email: this.client.email ?? '',
      telephone: this.client.telephone ?? '',
      otherInfoInsurer: this.policy.version.otherInfoInsurer ?? '',
      referral: this.client.referral ?? '',
      referralOther: this.client.referralOther ?? '',
    }
  }
}
