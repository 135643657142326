













































import Vue from '@/vue';
import Component from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import {BFormFile} from "bootstrap-vue";

@Component
export default class BiFormFile extends Vue {
  @Prop({type: Array, default: () => []}) value!: (File | string)[];
  @Prop({type: Boolean, default: false}) multiple!: boolean;
  @Prop({type: String, required: false, default: undefined}) accept?: string;

  get _value(): (File | string)[] {
    return this.value;
  }
  set _value(value: (File | string)[]) {
    this.$emit(
        'input',
        this.multiple
            ? [...value]
            : value
    );
  }

  @Prop({type: String, default: 'Browse'}) buttonLabel!: string;
  @Prop({type: String, default: 'Select files...'}) placeholder!: string;

  @Prop({type: String, default: 'md'}) tagsSize!: string;
  @Prop({type: String, default: 'primary'}) tagVariant!: string;

  resetInput() {
    this.$emit('input', []);
    this.$emit('reset');
  }

  get input(): BFormFile {
    return this.$refs.input as BFormFile;
  }

  focusInput() {
    (this.input.$el.querySelector('input.custom-file-input') as HTMLInputElement).click()
  }
}
