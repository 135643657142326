import Vue from '@/vue';
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_PATH,
});

api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
        (err.response?.status < 200 || err.response?.status > 299)
    ) {
      const message = err.response.data?.errors || err.response.data?.message || err.response.statusText || err.toString()

      console.log(message)
      return Promise.reject(message);
    }
    return Promise.reject(err);
  }
)

export default function (): void {
  Object.defineProperty(Vue.prototype, '$api', { value: api });
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $api: typeof api;
  }
}
