import {Client, CwpAnswers, Policy, PolicyCwp, PolicyVersion} from "@/types";
import {RegionLookupOption} from "@/store/lookups/getters";

export function showNumberOfBuildingsQuestion(policy: PolicyCwp | PolicyVersion<CwpAnswers>): boolean {
    // TODO replace lower-case descriptions with database lookup. Columns need adding in database for these questions.
    const description = policy.answers?.contractDescription.toLowerCase();
    if (!description) return true;

    if (description !== 'other') {
        return ![
            'transportable'
        ].includes(description);
    }

    // Description is other, refer to work type
    const workType = policy.answers?.workType?.toLowerCase();
    if (!workType) return true;

    return ![
        'sleepout/minor dwelling',
        'detached garage/workshop/outbuilding/shed',
    ].includes(workType);
}

export function showNumberOfDwellingsQuestion(policy: PolicyCwp | PolicyVersion<CwpAnswers>): boolean {
    // TODO replace lower-case descriptions with database lookup. Columns need adding in database for these questions.
    const description = policy.answers?.contractDescription.toLowerCase();
    if (!description) return true;

    if (description !== 'other') {
        return ![
            'transportable',
            'light commercial'
        ].includes(description);
    }
    
    // Description is other, refer to work type
    const workType = policy.answers?.workType?.toLowerCase();
    if (!workType) return true;

    return ![
        'fit out/re-fit',
        'detached garage/workshop/outbuilding/shed',
        'new light commercial building'
    ].includes(workType);
}

export function showNumberOfStoreysQuestion(policy: PolicyCwp | PolicyVersion<CwpAnswers>): boolean {
    // TODO replace lower-case descriptions with database lookup. Columns need adding in database for these questions.
    const description = policy.answers?.contractDescription.toLowerCase();
    if (!description) return true;

    if (description !== 'other') {
        return ![
            'transportable'
        ].includes(description);
    }

    // Description is other, refer to work type
    const workType = policy.answers?.workType?.toLowerCase();
    if (!workType) return true;

    return ![
        'sleepout/minor dwelling',
        'detached garage/workshop/outbuilding/shed',
    ].includes(workType);
}

export function showPage3(policy: PolicyCwp | PolicyVersion<CwpAnswers>): boolean {
    return showNumberOfBuildingsQuestion(policy) || showNumberOfDwellingsQuestion(policy) || showNumberOfStoreysQuestion(policy);
}

export function showPage4(client: Client, regions: RegionLookupOption[]): boolean {
    return regions
        .filter(item => item.isRiskCategoryRegion === true)
        .map(item => item.region)
        .includes(client.areaOfOp ?? '')
}

// TODO replace lower-case descriptions with database lookup. Columns need adding in database for these questions.
export function showPage5(policy: PolicyCwp | PolicyVersion<CwpAnswers>): boolean {
    const description = policy.answers?.contractDescription.toLowerCase();
    if (!description) return true;

    if (description !== 'other') {
        return ![
            'new residential building'
        ].includes(description);
    }

    const workType = policy.answers?.workType?.toLowerCase();
    if (!workType) return true;

    return ![
        'sleepout/minor dwelling',
        'detached garage/workshop/outbuilding/shed',
        'new light commercial building',
    ].includes(workType);
}
