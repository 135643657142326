import Vue from '@/vue';
import { Validation } from 'vuelidate';

export default function (): void {
  // Property validation wrapper
  Vue.prototype.$isValid = function (
    validation: Validation,
    validations?: (keyof typeof validation)[],
    anyDirty = false
  ): boolean {
    if (validation === null || validation === undefined) {
      return true;
    }
    const dirtyCheck = anyDirty ? validation.$anyDirty : validation.$dirty;
    if (validations !== undefined) {
      let valid = true;

      for (const v of validations) {
        valid = typeof validation[v] === 'boolean' ? validation[v] : false;
      }

      return dirtyCheck ? valid : true;
    } else {
      return dirtyCheck ? !validation.$invalid : true;
    }
  };
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $isValid: (validation?: Validation | null, anyDirty?: boolean) => boolean;
  }
}
