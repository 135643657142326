import { LookupState } from '../states';

const state: LookupState = {
  general: {},
  region: undefined,
  cities: undefined,
  sector: undefined,
  wording: {},
  interestedParty: undefined,
};
export default state;
