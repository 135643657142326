




























import Vue from '@/vue';

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BiFormBtnToggle extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String, required: false, default: '' }) description!: string;

  @Prop({ required: false, default: null }) value!: any | null;
  get _value() {
    return this.value;
  }
  set _value(value: boolean | null) {
    this.$emit('input', value);
  }

  @Prop({ type: Boolean, required: false, default: false })
  reverseOrder!: boolean;

  @Prop({ type: String, required: false, default: 'Yes' }) yesLabel!: string;
  @Prop({ required: false, default: true }) yesValue!: any;
  @Prop({ type: String, required: false, default: 'No' }) noLabel!: string;
  @Prop({ required: false, default: false }) noValue!: any;

  @Prop({ type: String, required: false, default: 'outline-primary' })
  variantInactive!: string;
  @Prop({ type: String, required: false, default: 'primary' })
  variantActive!: string;

  @Prop({ type: Boolean, required: false, default: false })
  inline!: boolean;
  @Prop({ type: Object, required: false, default: () => ({}) })
  buttonClasses!: { [c: string]: boolean };

  @Prop({ type: [Boolean, String], default: false }) error!: boolean | string;

  get hasError(): boolean {
    return (
      this.error === true || (typeof this.error === 'string' && !this.error)
    );
  }

  get _id(): string {
    return `input-${
      this.$attrs.id
        ? this.$attrs.id
        : this.label.replaceAll(' ', '-').toLowerCase()
    }`;
  }
}
