


























import Vue from '@/vue';

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface SelectOption {
  value: any;
  text: string;
  disabled?: boolean;
}

@Component
export default class BiFormMultiselect extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String, default: '' }) placeholder = '';
  @Prop({ type: String, default: undefined }) value?: string;

  @Prop({ type: Array, required: true }) options!: SelectOption[];

  @Prop({ type: String, default: 'md' }) tagsSize = 'md';
  @Prop({ type: Boolean, default: false }) tagPills = false;

  @Prop({ type: [Boolean, String], default: false }) error: boolean | string =
    false;

  get _value(): string | undefined {
    return this.value;
  }
  set _value(value: string | undefined) {
    this.$emit('input', value);
  }

  get hasError(): boolean {
    return (
      this.error === true || (typeof this.error === 'string' && !this.error)
    );
  }
}
