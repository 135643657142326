import Vue from '@/vue';
import Vuex from 'vuex';

import global from './global';
import lookups from './lookups';
import client from './client';
// import finance from './finance';

import { State } from './states';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  strict: true,
  modules: {
    global,
    lookups,
    client,
    // finance,
  },
});

export * from './states';
export * from './storeMap';
