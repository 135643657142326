import { ActionContext } from 'vuex';
import { LookupState, State } from '../states';
import * as lookups from '@/api/lookups';

export const getGeneralLookup = async (
  { state, commit }: ActionContext<LookupState, State>,
  {
    type,
    subtype,
  }: {
    type: string;
    subtype?: string;
  }
): Promise<void> => {
  let noLookup = state.general?.[type] === undefined;
  if (!noLookup && subtype !== undefined) {
    noLookup = state.general[type][subtype] === undefined;
  }
  if (noLookup) {
    try {
      const lookup = await lookups.getGeneralLookup(type, subtype);
      if (lookup !== undefined) {
        commit('general', { type, subtype, lookup });
      } else {
        throw new Error(`General Lookup ${type}.${subtype} does not exist`);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getRegionLookup = async ({
  state,
  commit,
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.region === undefined) {
    try {
      const lookup = await lookups.getRegionLookup();
      if (lookup !== undefined) {
        commit('region', { lookup });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getCitiesLookup = async ({
  state,
  commit,
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.cities === undefined) {
    try {
      const lookup = await lookups.getCitiesLookup();
      if (lookup !== undefined) {
        commit('cities', { lookup });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getSectorLookup = async ({
  state,
  commit,
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.sector === undefined) {
    try {
      const lookup = await lookups.getSectorLookup();
      if (lookup !== undefined) {
        commit('sector', { lookup });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getInterestedPartyLookup = async (
  { state, commit }: ActionContext<LookupState, State>,
  {
    type,
  }: {
    type: string;
  }
): Promise<void> => {
  if (state.interestedParty === undefined) {
    try {
      const lookup = await lookups.getInterestedPartyLookup(type);
      if (lookup !== undefined) {
        commit('interestedParty', { type, lookup });
      }
    } catch (e) {
      console.error(e);
    }
  }
};