




















































































































































































import { apiQuery } from '@/api';
import { Client } from '@/types';
import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import { helpers, required } from 'vuelidate/lib/validators';

const phone = (value: string) => {
  if (value !== undefined) {
    value = value.trim();
    // Match numeric, ignoring whitespace
    return (value.match(/^[\d +]*$/g) || [''])[0].length >= 5;
  }
  return true;
};

const email = helpers.regex(
  'email',
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
);

@Component
export default class BiContactUs extends Vue.extend({
  validations: {
    contactInfo: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      telephone: { required, phone },
    },
  },
  props: {
    value: {
      type: Object as PropType<Client>,
      required: false,
      default: () => ({} as Client),
    },
  },
}) {
  contactInfo: Partial<Client> = {};
  saving = false;

  get formInvalid(): boolean {
    return this.$v.contactInfo.$invalid || false;
  }

  get modal(): BModal {
    return this.$refs.modalContactUs as BModal;
  }

  saveData(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    this.saving = true;

    apiQuery(`/packages/${this.$packageHash}/contact`, { method: 'POST', data: this.contactInfo })
      .then(() => {
        this.$emit('input', this.contactInfo);
        this.modal.hide();
      })
      .catch(console.error)
      .finally(() => {
        this.saving = false;
      });
  }

  beforeMount(): void {
    const contactInfo = this.$clone(this.value);
    this.contactInfo = {
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
      email: contactInfo.email,
      telephone: contactInfo.telephone,
    };
  }
}
