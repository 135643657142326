import Vue from '@/vue';
import { GlobalState as State } from '..';

export const setFirstName = (
  state: State,
  { firstName }: { firstName?: string }
): void => {
  Vue.set(state, 'firstName', firstName);
};
export const setLastName = (
  state: State,
  { lastName }: { lastName?: string }
): void => {
  Vue.set(state, 'firstName', lastName);
};

export const setName = (
  state: State,
  { firstName, lastName }: { firstName?: string; lastName?: string }
): void => {
  Vue.set(state, 'firstName', firstName);
  Vue.set(state, 'lastName', lastName);
};

export const reset = (state: State): void => {
  Vue.set(state, 'firstName', false);
  Vue.set(state, 'lastName', false);
};
