import { ApiResource } from '@/types';
import { api } from '@/init/api';
import { AxiosRequestConfig } from 'axios';

export async function apiQuery<TData>(url: string): Promise<TData>;
export async function apiQuery<TData>(
  url: string,
  options?: AxiosRequestConfig
): Promise<TData>;
export async function apiQuery<TData>(
  url: string,
  options: AxiosRequestConfig = {}
): Promise<TData> {
  return (await api.request<ApiResource<TData>>({ url, ...options })).data.data;
}
