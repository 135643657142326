






















































import { SelectOption } from '@/types/SelectOption';
import Vue from '@/vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BiSelect extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly options!: SelectOption[];
  @Prop({ type: [String, Array], default: () => [] }) readonly value!:
    | SelectOption['value']
    | SelectOption['value'][];

  @Prop({ type: String, default: '' }) readonly placeholder!: string;
  @Prop({ type: String, default: '' }) readonly description!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
  @Prop({ type: String, default: 'primary' }) readonly tagVariant!: string;
  @Prop({ type: String, default: '' }) readonly tagsClass!: string;
  @Prop({ type: String, default: 'md' }) readonly tagsSize!: string;
  @Prop({ type: Boolean, default: false }) readonly tagPills!: boolean;

  selectValue: SelectOption['value'] = '';

  get _value(): SelectOption['value'] {
    return this.multiple || typeof this.value === 'object' ? '' : this.value;
  }
  set _value(value: SelectOption['value']) {
    this.$emit('input', value);
  }

  onOptionClick(option: SelectOption['value']): void {
    if (!this.multiple || typeof this.value !== 'object') {
      return;
    }

    this.selectValue = '';
    this.$emit('input', [...this.value, option]);
  }

  get availableOptions(): SelectOption[] {
    return this.multiple && typeof this.value === 'object'
      ? this.options.filter(
          (o) => !(this.value as SelectOption['value'][]).includes(o.value)
        )
      : this.options;
  }

  get valueOptions(): SelectOption[] {
    return this.multiple && typeof this.value === 'object'
      ? this.value.map(
          (v) =>
            this.options.find((o) => o.value === v) ?? { text: v, value: v }
        )
      : [];
  }

  removeTag(index: number): void {
    if (typeof this.value === 'object') {
      this.$emit(
        'input',
        this.value.filter((v, i) => i !== index)
      );
    }
  }
}
