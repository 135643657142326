





























import Vue from '@/vue';

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface SelectOption {
  value: any;
  text: string;
  disabled?: boolean;
}

@Component
export default class BiFormSelect extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: [String, Array], default: undefined }) value?:
    | SelectOption['value']
    | SelectOption['value'][];

  @Prop({ type: Array, required: true }) options!: SelectOption[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;

  @Prop({ type: [Boolean, String], default: false }) error!: boolean | string;

  get _value(): string | undefined {
    return this.value;
  }
  set _value(value: string | undefined) {
    this.$emit('input', value);
  }

  get hasError(): boolean {
    return (
      this.error === true || (typeof this.error === 'string' && !this.error)
    );
  }

  get _id(): string {
    return `input-${
      this.$attrs.id
        ? this.$attrs.id
        : this.label.replaceAll(' ', '-').toLowerCase()
    }`;
  }
}
