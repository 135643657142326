




















































import Vue from '@/vue';
import moment from 'moment-timezone';

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BiFormDatepicker extends Vue {
  @Prop({ type: String, required: false }) label?: string;
  @Prop({ type: String, required: false }) value?: string;

  get _value(): Date | undefined {
    if (this.value !== undefined && this.value !== null && this.value !== '') {
      return moment(this.value, 'DD/MM/YYYY').toDate();
    } else {
      return undefined;
    }
  }

  set _value(value: Date | undefined) {
    this.$emit('input', moment(value).format('DD/MM/YYYY'));
  }

  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: [Boolean, String], default: false }) error: boolean | string =
      false;

  @Prop({ type: Date, required: false }) min?: Date;
  @Prop({ type: Date, required: false }) max?: Date;

  get _id(): string {
    return `input-${
        this.$attrs.id
            ? this.$attrs.id
            : this.label?.replaceAll(' ', '-').toLowerCase() ?? ''
    }`;
  }
}
